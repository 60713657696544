import { AuthStore } from './AuthStore';
import { useContext, createContext } from 'react';
import { NotificationStore } from './NotificationStore';
import { BundleStore } from './BundleStore';

export interface IStore {
  authStore: AuthStore;
  notificationStore: NotificationStore;
  bundleStore: BundleStore;
}

export const createStore = (): IStore => ({
  authStore: new AuthStore(),
  notificationStore: new NotificationStore(),
  bundleStore: new BundleStore()
});

export const StoreContext = createContext(createStore());

export const useStores = () => useContext(StoreContext);
