import { IUserSignInRequest } from 'types/User';
import { axiosInstances } from './Axios';

export const requestForgotPassword = (username: string) =>
  axiosInstances
    .public()
    .post('/app_users/password', { app_user: { username } })
    .then(response => response.data);

export const requestForgotUsername = (email: string) =>
  axiosInstances
    .public()
    .post('/app_users/forgot_username', { app_user: { email } })
    .then(response => response.data);

export const initialChangePassword = (
  current_password: string,
  password: string
) =>
  axiosInstances
    .secured()
    .put('/app_users', { app_user: { password, current_password } })
    .then(response => response.data);

export const resetPassword = ({
  token,
  password
}: {
  token: string;
  password: string;
}) =>
  axiosInstances
    .public()
    .put('/app_users/password', {
      app_user: { reset_password_token: token, password }
    })
    .then(response => response.data);

export const signIn = (signInRequest: IUserSignInRequest) =>
  axiosInstances
    .public()
    .post('/app_users/login', signInRequest)
    .then(response => response.data);

export const signOut = () =>
  axiosInstances
    .secured()
    .delete('/app_users/logout')
    .then(response => response.data);
