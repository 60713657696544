import { makeStyles } from '@material-ui/core';

export const loadingScreen = makeStyles({
  container: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
