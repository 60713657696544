import { makeStyles } from '@material-ui/styles';
import 'assets/fonts/calibre/calibre.css';

export const globalStyles = makeStyles({
  '@global': {
    body: {
      margin: 0
    }
  }
});
