import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import * as styles from './styles';

export const LoadingScreen = () => {
  const { container } = styles.loadingScreen();

  return (
    <div className={container}>
      <CircularProgress />
    </div>
  );
};
