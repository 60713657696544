import axios from 'axios';
import jsCookie from 'js-cookie';

const axiosInstance = (isSecured?: boolean) => {
  if (!isSecured) {
    return axios.create({
      baseURL: process.env.REACT_APP_BASE_API_URL,
      headers: {
        Accept: 'application/json'
      }
    });
  }

  const user = jsCookie.get('AUTHENTICATION_USER');
  const authenticationToken = jsCookie.get('AUTHENTICATION_TOKEN');
  const username = user && JSON.parse(user).username;

  return axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    headers: {
      'X-App-User-Username': username,
      'X-App-User-Token': authenticationToken,
      Accept: 'application/json'
    }
  });
};

export const axiosInstances = Object.freeze({
  public: () => axiosInstance(),
  secured: () => axiosInstance(true)
});
