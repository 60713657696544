import jsCookie from 'js-cookie';
import { action, observable, runInAction } from 'mobx';
import * as AuthService from 'services/AuthService';
import { IUser, IUserSignInRequest } from 'types/User';

interface IAuthStoreState {
  user?: IUser;
  authenticationToken?: string;
}

export class AuthStore {
  state: IAuthStoreState = observable({
    user: undefined
  });

  constructor() {
    this.init();
  }

  @action.bound
  async login(signInRequest: IUserSignInRequest) {
    try {
      const { app_user: user, authentication_token } = await AuthService.signIn(
        signInRequest
      );

      if (!user) {
        // eslint-disable-next-line
        throw 'Invalid user';
      }

      this.saveAuthDataToCookie({
        user,
        authenticationToken: authentication_token
      });

      runInAction('loginAsync', () => {
        this.state.user = user;
        this.state.authenticationToken = authentication_token;
      });
    } catch (error) {
      if (error.response && error.response.data) {
        throw error.response.data.error;
      }

      // eslint-disable-next-line
      throw 'Error signing in';
    }
  }

  @action.bound
  async logout() {
    try {
      await AuthService.signOut();
    } catch (err) {
      // Swallow this error for now
    }

    jsCookie.remove('AUTHENTICATION_USER');
    jsCookie.remove('AUTHENTICATION_TOKEN');

    runInAction('logoutAsync', () => {
      this.state.user = undefined;
      this.state.authenticationToken = undefined;
    });
  }

  async requestForgotUsername(email: string) {
    return AuthService.requestForgotUsername(email);
  }

  async requestForgotPassword(username: string) {
    try {
      return await AuthService.requestForgotPassword(username);
    } catch (error) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }

      // eslint-disable-next-line
      throw 'Error resetting password';
    }
  }

  @action.bound
  async initialChangePassword(currentPassword: string, password: string) {
    if (!this.state.user) {
      // eslint-disable-next-line
      throw 'Invalid user';
    }

    try {
      await AuthService.initialChangePassword(currentPassword, password);

      jsCookie.set(
        'AUTHENTICATION_USER',
        JSON.stringify({
          ...this.state.user,
          change_password_required: false
        }),
        {
          expires: 1
        }
      );

      runInAction('changePasswordAsync', () => {
        if (!this.state.user) {
          return;
        }

        this.state.user.change_password_required = false;
      });
    } catch (error) {
      if (error.response && error.response.data) {
        throw error.response.data.errors;
      }

      // eslint-disable-next-line
      throw 'Error changing password';
    }
  }

  async resetPassword(token: string, password: string) {
    try {
      return await AuthService.resetPassword({ token, password });
    } catch (error) {
      if (error.response && error.response.data) {
        throw error.response.data.errors;
      }

      // eslint-disable-next-line
      throw 'Error changing password';
    }
  }

  @action.bound
  init() {
    const user = jsCookie.get('AUTHENTICATION_USER');
    const authenticationToken = jsCookie.get('AUTHENTICATION_TOKEN');

    try {
      this.state.user = user && JSON.parse(user);
      this.state.authenticationToken = authenticationToken;
    } catch (error) {
      console.log(error);
    }
  }

  saveAuthDataToCookie = ({
    user,
    authenticationToken
  }: {
    user: IUser;
    authenticationToken: string;
  }) => {
    jsCookie.set('AUTHENTICATION_USER', JSON.stringify(user), {
      expires: 1
    });
    jsCookie.set('AUTHENTICATION_TOKEN', authenticationToken, {
      expires: 1
    });
  };
}
