import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { ThemeProvider, StylesProvider } from '@material-ui/styles';
import { configure } from 'mobx';
import React from 'react';
import { RouteManager } from './RouteManager';
import { globalStyles } from './globalStyles';
import { SnackbarsContainer } from 'components/snackbars-container';

configure({ enforceActions: 'always' });
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Calibre'
  },
  palette: {
    primary: { main: '#4cc8ae' }
  },
  mixins: {
    toolbar: {
      minHeight: 48
    }
  }
});

const App: React.FC = () => {
  globalStyles();

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider>
        <RouteManager />
        <SnackbarsContainer />
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
