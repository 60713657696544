import { useState, useCallback } from 'react';
import { FormikConfig } from 'formik';

export const useFormHandler = <FormData>(
  onSubmit: FormikConfig<FormData>['onSubmit']
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [backendErrors, setBackendErrors] = useState(undefined);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const handleSubmit = useCallback(
    async (data: FormData, formikActions) => {
      setIsLoading(true);
      setIsSubmitSuccess(false);
      setBackendErrors(undefined);

      try {
        await onSubmit(data, formikActions);

        setIsSubmitSuccess(true);
      } catch (error) {
        setBackendErrors(error);
      } finally {
        setIsLoading(false);
      }
    },
    [onSubmit]
  );

  return {
    isLoading,
    isSubmitSuccess,
    backendErrors,
    handleSubmit
  };
};
