import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { useFirstNotification } from 'hooks/NotificationHooks';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import * as styles from './styles';

export const SnackbarsContainer: React.FunctionComponent = observer(() => {
  const { container } = styles.snackbars();
  const firstNotification = useFirstNotification();
  const [notification, closeNotification] = firstNotification || [
    undefined,
    undefined
  ];

  return (
    <React.Fragment>
      {notification && (
        <Snackbar
          className={container}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open
          onClose={closeNotification}
          message={notification.message}
          action={[
            <IconButton
              onClick={closeNotification}
              key="close"
              aria-label="Close"
              color="inherit"
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      )}
    </React.Fragment>
  );
});
