import { axiosInstances } from './Axios';
import { IBundleResponse, IBundle } from 'types/Bundle';
import moment from 'moment';

export const getBundles = () =>
  axiosInstances
    .secured()
    .get('/patient_cart_emails')
    .then(response => response.data);

export const removeBundle = (bundle: IBundle) =>
  axiosInstances
    .secured()
    .put(`/patient_cart_emails/${bundle.id}`, {
      patient_cart_email: {
        recipient_app_deleted: true
      }
    })
    .then(response => response.data);

export const transformBundleResponse = ({
  content_link,
  cart_title,
  thumbnail_image_url,
  sender_name,
  bundle_program_title,
  created_at,
  landing_page_visited_at,
  item_count,
  ...rest
}: IBundleResponse): IBundle => ({
  contentLink: content_link,
  cartTitle: cart_title,
  senderName: sender_name,
  thumbnailImageUrl: thumbnail_image_url,
  createdAt: moment(created_at),
  bundleProgramTitle: bundle_program_title,
  landingPageVisitedAt: landing_page_visited_at,
  itemCount: item_count,
  ...rest
});
