import { action, observable, ObservableMap, runInAction, computed } from 'mobx';
import * as BundleService from 'services/BundleService';
import { IBundle, IBundleResponse } from 'types/Bundle';
import moment from 'moment';

interface IBundleStoreState {
  bundles: ObservableMap<string, IBundle>;
}

export class BundleStore {
  static defaultState = {
    bundles: new ObservableMap()
  };

  state: IBundleStoreState = observable(BundleStore.defaultState);

  @computed
  get bundleList() {
    return Array.from(this.state.bundles.values()).sort((bundleA, bundleB) =>
      moment(bundleA.createdAt).isAfter(bundleB.createdAt) ? -1 : 1
    );
  }

  @action.bound
  reset() {
    this.state.bundles = new ObservableMap();
  }

  @action.bound
  async getBundles() {
    const { data: bundles } = await BundleService.getBundles();

    runInAction('getBundlesAsync', () => {
      bundles
        .map((bundle: IBundleResponse) =>
          BundleService.transformBundleResponse(bundle)
        )
        .forEach((bundle: IBundle) => {
          this.state.bundles.set(bundle.id, bundle);
        });
    });
  }

  @action.bound
  async removeBundle(bundle: IBundle) {
    const { data: _bundle } = await BundleService.removeBundle(bundle);

    runInAction('removeBundleAsync', () => {
      this.state.bundles.delete(_bundle.id);
    });
  }
}
