import { useAuth } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router';

export const PrivateRoute: React.FunctionComponent<RouteProps> = observer(
  ({ component: Component, render, ...rest }) => {
    const { user } = useAuth();

    const renderOrRedirect = (props: RouteComponentProps<any>) => {
      if (!user || (!Component && !render)) {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        );
      }

      if (user && user.change_password_required) {
        return (
          <Redirect
            to={{
              pathname: '/change-password',
              state: { from: props.location }
            }}
          />
        );
      }

      if (render) {
        return render(props);
      }

      if (Component) {
        return <Component {...props} />;
      }
    };

    return <Route {...rest} render={renderOrRedirect} />;
  }
);
