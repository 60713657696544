import { action, computed, observable, ObservableMap, runInAction } from 'mobx';
import shortid from 'shortid';
import { INotification } from 'types/Notification';
const NOTIFICATION_EXPIRY_TIME = 3000;

interface INotificationStoreState {
  notifications: ObservableMap<string, INotification>;
}

export class NotificationStore {
  static defaultState = {
    notifications: new ObservableMap()
  };

  state: INotificationStoreState = observable(NotificationStore.defaultState);

  @action.bound
  reset() {
    this.state = NotificationStore.defaultState;
  }

  @action.bound
  addNotification(notification: INotification) {
    const id = shortid.generate();
    this.state.notifications.set(id, notification);

    setTimeout(() => {
      runInAction('removeScheduled', () => {
        this.state.notifications.delete(id);
      });
    }, NOTIFICATION_EXPIRY_TIME);
  }

  @action.bound
  removeNotification(id: string) {
    this.state.notifications.delete(id);
  }

  @computed
  get firstNotification() {
    const [firstNotification] = Array.from(this.state.notifications.entries());

    return firstNotification;
  }
}
