import { LoadingScreen } from 'components/loading-screen';
import { observer } from 'mobx-react-lite';
import { LoginPage } from 'pages/login-page';
import { InboxPage } from 'pages/inbox-page';
import { ForgotUsernamePage } from 'pages/forgot-username-page';
import * as React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useAuth } from 'hooks/AuthHooks';
import { PrivateRoute } from 'components/private-route';
import { ForgotPasswordPage } from 'pages/forgot-password-page';
import { ChangePasswordPage } from 'pages/change-password-page';
import { ResetPasswordPage } from 'pages/reset-password-page';

export const RouteManager: React.FunctionComponent = observer(() => {
  const { user } = useAuth();

  return (
    <BrowserRouter>
      <React.Suspense fallback={<LoadingScreen />}>
        <Switch>
          <Redirect exact from="/" to={user ? '/inbox' : '/login'} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/forgot-username" component={ForgotUsernamePage} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <Route exact path="/change-password" component={ChangePasswordPage} />
          <Route
            exact
            path="/reset-password/:token"
            component={ResetPasswordPage}
          />
          <PrivateRoute exact path="/inbox" component={InboxPage} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
});
