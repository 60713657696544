import { useCallback, useMemo } from 'react';
import { useStores } from 'stores';
import { INotification } from 'types/Notification';

export const useFirstNotification = ():
  | undefined
  | [INotification, () => void] => {
  const {
    notificationStore: { firstNotification, removeNotification }
  } = useStores();

  const [id, notification] = firstNotification || [undefined, undefined];

  const closeNotification = useCallback(() => removeNotification(id), [
    id,
    removeNotification
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => firstNotification && [notification, closeNotification], [
    notification,
    closeNotification
  ]) as [INotification, () => void];
};
