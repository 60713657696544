import { useStores } from 'stores';
import { RouteComponentProps } from 'react-router-dom';
import { useEffect } from 'react';

export const useAuth = () => {
  const {
    authStore: {
      state: { user },
      login,
      logout,
      requestForgotPassword,
      requestForgotUsername
    },
    bundleStore: { reset: resetBundle },
    notificationStore: { reset: resetNotification }
  } = useStores();

  const logoutFunction = async () => {
    await logout();

    resetBundle();
    resetNotification();
  };

  return {
    user,
    login,
    logout: logoutFunction,
    requestForgotUsername,
    requestForgotPassword
  };
};

export const useRedirectOnceLogin = (
  history: RouteComponentProps['history'],
  url = '/'
) => {
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      return;
    }

    history.push(url);
  }, [user, history, url]);
};
